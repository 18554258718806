/* eslint-disable new-cap, no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { usePubSub, useMeeting } from '@videosdk.live/react-sdk';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash.isempty';
import AppointmentAPI from 'libs/api/appointment';
import ImageUpload from 'components/ImageUpload';
import ModalPhoto from 'components/ModalPhoto';
import { useFilePicker } from 'use-file-picker';
import Png from 'components/Png';
import { sendMessage, formatMessages } from 'libs/message';
import isAvailable, { FEATURE_ODD_APPOINTMENT, FEATURE_HIGO, FEATURE_HIDE_PATIENT_UPLOAD_BUTTON, FEATURE_HIDE_DOCTOR_UPLOAD_BUTTON } from 'libs/feature-flags';
import {
  CHAT_SYSTEM_MESSAGE,
  EVENT_PUBSUB,
  FORMATS_MESSAGE,
  MESSAGE_SENDER,
  ODD_SSE_EVENTS,
  ODD_SSE_CONFIG
} from 'libs/constant';
import useChat from 'libs/hooks/useChat';
import MessageList, {
  ConsultationPrivacy,
  ConsultationLoading,
  ConsultationODDNotice,
  SystemMessage
} from '../partial/message-list';
import { ChatInputGroup, ErrorMessage } from './styles';
import QueueComponent from './queue-component';
import { useGlobalConfigStore } from 'zustandStore';
import { useODDSSEContext } from 'libs/contexts/odd';
import { FileText, Paperclip } from 'phosphor-react';
import UploadPopover from 'components/molecules/upload-popover';
import runtimeConfig from 'libs/runtime-config';

const MAX_LENGTH_CHAT = process.env.REACT_APP_MAX_LENGTH_CHAT ?? 200;

const ChatView = ({
  isPatient,
  telemedicineStatus,
  country,
  lang,
  showFullChat,
  setShowFullChat,
  wlVersion,
  showVideo,
  setShowVideo,
  showChat,
  setShowChat,
  saveFacilityDocument = () => {}
}) => {
  const { t } = useTranslation();
  const {
    value: { appointment, doctor, patient, isDoctor, telemedicine, telemedicineQueue },
    action
  } = useChat();
  const fileValidator = {
    validateBeforeParsing: async (_, plainFiles) =>
      new Promise((resolve, reject) => {
        const file = plainFiles[0];
        if (!/jpeg|jpg|png|pdf/.test(file.type)) {
          reject({
            fileTypeInvalid: true
          });
        }
        resolve();
      }),
    validateAfterParsing: async () =>
      new Promise(resolve => {
        resolve();
      })
  };
  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['.png', '.jpeg', '.pdf', '.jpg'],
    multiple: false,
    limitFilesConfig: { min: 1, max: 1 },
    maxFileSize: runtimeConfig?.REACT_APP_OKA_PUBLIC_CHAT_MAX_FILE_SIZE_UPLOAD ?? 2.1,
    validators: [fileValidator]
  });
  const fileRef = useRef();
  const localMessagesRef = useRef();
  const { publish, messages } = usePubSub(EVENT_PUBSUB.CHAT, {});
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(false);
  const [localMessages, setLocalMessages] = useState([]);
  const [localTempImage, setLocalTempImage] = useState({});
  const [localTempPatientDocument, setLocalTempPatientDocument] = useState({});
  const [localTempExamReport, setLocalTempExamReport] = useState({});
  const [photo, setPhoto] = useState();
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const chatLog = React.createRef();
  const meetingSdk = useMeeting();
  const [queueData, setQueueData] = useState({});

  const { addSubscriber } = useODDSSEContext();

  const { toggleMic, toggleWebcam, localWebcamOn, localMicOn } = useMeeting();
  const leavedScreenStates = useGlobalConfigStore(state => state.leavedScreenStates);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const webcamOn = useGlobalConfigStore(state => state.webcamOn);
  const micOn = useGlobalConfigStore(state => state.micOn);
  const setIsShowAppDrawerUpload = useGlobalConfigStore(state => state.setIsShowAppDrawerUpload);
  const setIsShowExamModal = useGlobalConfigStore(state => state.setIsShowExamModal);
  const hasExamDevice = telemedicine?.isExamDevice;

  const sendFile = useCallback(
    ({ format, message, time, sender, fileType: filetype, chatHistoryId }) => {
      let messageToSent = {
        appointmentNumber: appointment.appointmentNumber,
        message,
        text: message,
        format,
        sender,
        time
      };
      if (filetype) {
        messageToSent = { ...messageToSent, filetype };
      }
      if (chatHistoryId) {
        messageToSent = { ...messageToSent, chatHistoryId };
      }
      return messageToSent;
    },
    [appointment.appointmentNumber]
  );

  const sendTextMessage = time => {
    const messageToSent = {
      appointmentNumber: appointment.appointmentNumber,
      message,
      text: message,
      format: 'text',
      sender: isPatient ? MESSAGE_SENDER.PATIENT : MESSAGE_SENDER.DOCTOR,
      time
    };
    return messageToSent;
  };

  const handleSaveChat = async (messageToSent, time) => {
    const format = messageToSent.format;
    const data = {
      appointmentNumber: appointment.appointmentNumber,
      message: {
        format,
        text: messageToSent.message,
        time
      }
    };
    action.saveChat(data);
  };

  const doUploadFile = async () => {
    const dateNow = new Date().toISOString();
    const messageToLoading = sendFile({
      format: 'loading',
      message: Math.floor(Date.now() / 1000),
      time: dateNow,
      sender: isPatient ? MESSAGE_SENDER.PATIENT : MESSAGE_SENDER.DOCTOR,
      fileType: fileRef?.current?.type
    });
    const msgs = formatMessages(localMessagesRef.current.concat(messageToLoading));
    localMessagesRef.current = msgs;
    setLocalMessages(msgs);
    clear();
    setMessage('');
    const response = await action.saveImage({
      appointmentNumber: appointment.appointmentNumber,
      image: fileRef?.current?.content || '',
      filename: fileRef?.current?.filename
    });

    if (response) {
      const messageToImage = sendFile({
        format: 'image',
        message: response.data.data.url,
        sender: isPatient ? MESSAGE_SENDER.PATIENT : MESSAGE_SENDER.DOCTOR,
        fileType: fileRef?.current?.type
      });
      handlePublishMessage(messageToImage);
      handleSaveChat(messageToImage, dateNow);
    }
    setLocalMessages(formatMessages(localMessagesRef.current.filter(m => m.format !== 'loading')));
  };

  const handleSendDocumentConsent = () => {
    if (plainFiles.length > 0) {
      sendMessage(
        {
          message: {
            type: 'upload_document',
            created_by: isPatient ? appointment?.patient : appointment?.practitioner,
            document_title: plainFiles[0].name,
            last_updated_time: new Date().toISOString(),
            facility_name: isPatient ? doctor?.location?.name : [patient?.first_name, patient?.last_name].join(' '),
            country,
            lang
          }
        },
        '*'
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePublishMessage = async messageToSent => {
    if (messageToSent.message.length) {
      const messageStr = JSON.stringify(messageToSent);

      switch (messageToSent?.format) {
        // If has image format, set the message object into state and will processed in the hooks
        case FORMATS_MESSAGE.IMAGE:
          setLocalTempImage(messageStr);
          break;
        // If has patient document format, set the message object into state and will processed in the hooks
        case FORMATS_MESSAGE.PATIENT_DOCUMENT:
          setLocalTempPatientDocument(messageStr);
          break;
        case FORMATS_MESSAGE.EXAM_REPORT:
          setLocalTempExamReport(messageStr);
          break;
        default:
          publish(messageStr, { persist: false });
          break;
      }
      setMessage('');
      clear();
    }
  };

  const handleSubmitMessage = async () => {
    const dateNow = new Date().toISOString();
    if (filesContent.length > 0 && plainFiles.length > 0) {
      fileRef.current = {
        content: filesContent[0].content,
        type: plainFiles[0].type,
        filename: plainFiles[0].name
      };
      // only skip consent if coming from whitelabel version 1
      if (wlVersion === '1') {
        doUploadFile();
      } else {
        handleSendDocumentConsent();
      }
    } else {
      if (message.trim()) {
        const messageToSent = sendTextMessage(dateNow);
        handlePublishMessage(messageToSent);
        handleSaveChat(messageToSent, dateNow);
      }
    }
  };

  const handleChangeMessage = e => {
    const message = e.target.value;
    setMessage(message);
  };

  const onKeyDownMessage = e => {
    if (e.key === 'Enter') {
      handleSubmitMessage();
      e.preventDefault();
    }
  };

  const onKeyUpMessage = e => {
    if (e.target.value.length > MAX_LENGTH_CHAT) {
      e.preventDefault();
    }
  };

  const scrollToBottom = useCallback(() => {
    chatLog.current.scrollTop = chatLog.current.scrollHeight;
  }, [chatLog]);

  const handleCloseModal = () => {
    setPhoto(null);
    setIsPhotoModalOpen(false);
  };

  const handleOpenPhoto = photo => {
    setPhoto(photo);
    setIsPhotoModalOpen(true);
  };

  const handleGetMessage = async e => {
    let message;
    try {
      message = JSON.parse(e.data);
    } catch (error) {
      message = {
        type: ''
      };
    }
    const isDoUpload = (message.type || '').toLowerCase() === 'do_upload';
    const isCancelUpload = (message.type || '').toLowerCase() === 'cancel_upload';
    if (isCancelUpload) {
      clear();
      setMessage('');
    } else if (isDoUpload) {
      if (!isPatient && isAvailable([FEATURE_ODD_APPOINTMENT])) {
        saveFacilityDocument();
      } else {
        doUploadFile();
      }
    }
  };

  const getChatHistory = useCallback(
    async () => {
      setIsLoadingChatHistory(true);
      const [, response] = await AppointmentAPI.GetChatHistory({
        appointmentNumber: appointment.appointmentNumber
      });
      const data = response?.data;
      if (data) {
        setChatHistory(data.data);
        setIsLoadingChatHistory(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFilePicker = () => {
    openFileSelector();
  };

  // Set callback to the ODD SSE of uploaded_document event
  const listenToUploadedDocumentEvent = useCallback(() => {
    addSubscriber({
      type: ODD_SSE_EVENTS.UPLOADED_DOCUMENT,
      config: [ODD_SSE_CONFIG.PATIENT],
      handler: payload => {
        const { document_title: docName, document_extension: filetype, chat_history_id: chatHistoryId } = payload;
        if (docName) {
          // show uploaded document as chat history
          const time = new Date().toISOString();
          const messageToSent = {
            appointmentNumber: appointment?.appointmentNumber,
            message: docName,
            text: docName,
            format: FORMATS_MESSAGE.PATIENT_DOCUMENT,
            sender: MESSAGE_SENDER.SYSTEM,
            filetype,
            chatHistoryId,
            time,
            meta: {
              document_title: docName,
              document_extension: filetype
            }
          };
          handlePublishMessage(messageToSent);
        }
      }
    });
  }, [appointment?.appointmentNumber, addSubscriber, handlePublishMessage]);

  const handleShowVideo = () => {
    setShowVideo(true);
    setShowFullChat(false);
    setShowChat(false);

    if (appointment?.isODDConsultation) {
      toggleState({ isPatientJoinTheCall: true });
    }
  };

  const onClickJoinCall = () => {
    // toggle video afer the participant hang up the meeting previously
    if (!showVideo && leavedScreenStates) {
      // toggle the webcam to the latest its state
      if ((leavedScreenStates?.webcamOn && !localWebcamOn) || (!leavedScreenStates?.webcamOn && localWebcamOn)) {
        toggleWebcam();
      }
      // toggle the mic to the latest its state
      if ((leavedScreenStates?.micOn && !localMicOn) || (!leavedScreenStates?.micOn && localMicOn)) {
        toggleMic();
      }

      toggleState({
        webcamOn: leavedScreenStates?.webcamOn || webcamOn,
        micOn: leavedScreenStates?.micOn || micOn,
        leavedScreenStates: null
      });
    }

    if (!isDoctor && appointment?.isODDConsultation) {
      action.handleShowAppDrawer({
        checkActive: true,
        callback: handleShowVideo
      });
    } else {
      handleShowVideo();
    }
  };

  /**
   * Set document into chat room only by publish.
   * Because publish doesn't work on postmessage after succesfully upload the image and
   * clear the local image temp when state was published with default value "{}"
   */
  useEffect(() => {
    if (!_isEmpty(localTempImage)) {
      publish(localTempImage, { persist: false });
      setLocalTempImage({});
    }
  }, [localTempImage, publish]);

  /**
   * Set patient document from 3rdparty into chat room only by publish.
   * Because if directly use `handlePublishMessage` it makes multiple view on chat room
   * clear the local patient document temp when state was published with default value "{}"
   */
  useEffect(() => {
    if (!_isEmpty(localTempPatientDocument)) {
      publish(localTempPatientDocument, { persist: false });
      setLocalTempPatientDocument({});
    }
  }, [localTempPatientDocument, publish]);

  useEffect(() => {
    if (appointment?.isODDConsultation) {
      listenToUploadedDocumentEvent();
    }
  }, [appointment, listenToUploadedDocumentEvent]);

  /**
   * Subcribe Exam Report from SSE and call it in practitioner room only
   */
  useEffect(() => {
    if (telemedicine?.isExamDevice) {
      addSubscriber({
        type: ODD_SSE_EVENTS.EXAM_REPORT,
        config: [ODD_SSE_CONFIG.PATIENT],
        handler: payload => {
          const messageToExamReport = sendFile({
            format: FORMATS_MESSAGE.EXAM_REPORT,
            message: payload?.document_title,
            time: new Date().toISOString(),
            sender: MESSAGE_SENDER.PATIENT, // always use 'patient' for this if called by SSE from practitioner side
            chatHistoryId: payload?.chat_history_id
          });
          handlePublishMessage(messageToExamReport);
        }
      });
    }
  }, [telemedicine?.isExamDevice, addSubscriber, sendFile, handlePublishMessage]);

  /**
   * Set exam report from SSE into chat room only by publish.
   * Because if directly use `handlePublishMessage` it makes multiple view on chat room
   * clear the local exam report temp when state was published with default value ""
   */
  useEffect(() => {
    if (!_isEmpty(localTempExamReport)) {
      publish(localTempExamReport, { persist: false });
      setLocalTempExamReport({});
    }
  }, [localTempExamReport, publish]);

  useEffect(() => {
    const hasPatientJoinVideo =
      localMessages.filter(item => item.sender === 'patient' && item.format === FORMATS_MESSAGE.VIDEO_JOIN).length > 0;

    action?.setIsVideoStarted(hasPatientJoinVideo);

    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMessages]);

  useEffect(() => {
    setLocalMessages(formatMessages(chatHistory));
    localMessagesRef.current = formatMessages(chatHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistory]);

  useEffect(() => {
    if (messages.length > 0) {
      const msgs = localMessages.concat(messages);
      setLocalMessages(formatMessages(msgs));
      localMessagesRef.current = formatMessages(msgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    setQueueData({ ...queueData, ...telemedicineQueue });
    getChatHistory();
    window.addEventListener('message', handleGetMessage);

    return () => window.removeEventListener('message', handleGetMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meetingSdk.isMeetingJoined) {
      if (showFullChat && meetingSdk.localWebcamOn) {
        meetingSdk.toggleWebcam();
      }
      if (showFullChat && meetingSdk.localMicOn) {
        meetingSdk.toggleMic();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingSdk.localWebcamOn, meetingSdk.localMicOn, meetingSdk.isMeetingJoined]);
  const showODDPatientContent = isPatient && appointment?.isODDConsultation;

  useEffect(() => {
    if (appointment?.appointmentNumber && queueData && showODDPatientContent) {
      addSubscriber({
        type: ODD_SSE_EVENTS.UPDATE_PARTICIPANT,
        handler: payload => {
          setQueueData({ ...queueData, ...payload });
        }
      });

      addSubscriber({
        type: ODD_SSE_EVENTS.TOTAL_QUEUE,
        config: [ODD_SSE_CONFIG.PATIENT],
        handler: payload => {
          setQueueData({ ...queueData, ...payload });
        }
      });
    }
  }, [appointment, queueData, showODDPatientContent, addSubscriber]);

  const onClickUploadAttachment = () => {
    if (!isPatient && isAvailable([FEATURE_ODD_APPOINTMENT])) {
      setIsShowAppDrawerUpload(true);
    } else {
      handleFilePicker();
    }
  };

  const onClickExamReport = () => {
    setIsShowExamModal(true);
  };

  const isUploadButtonHidden = isPatient
    ? isAvailable([FEATURE_HIDE_PATIENT_UPLOAD_BUTTON])
    : isAvailable([FEATURE_HIDE_DOCTOR_UPLOAD_BUTTON]);

  return (
    <>
      {!showChat && showODDPatientContent && (
        <QueueComponent data={queueData} t={t} onClickJoinCall={onClickJoinCall} />
      )}
      <div className="chat__log" ref={chatLog}>
        <div className="container">
          {isLoadingChatHistory && <ConsultationLoading t={t} />}
          {isPatient && <ConsultationPrivacy t={t} />}
          {showODDPatientContent && <ConsultationODDNotice t={t} />}
          {showODDPatientContent && <SystemMessage message={CHAT_SYSTEM_MESSAGE.WAITING_ON_PATIENT} />}
          <MessageList
            appointment={appointment}
            isPatient={isPatient}
            messages={localMessages}
            doctorPicture={doctor?.profilePicture}
            patientPicture={patient?.profilePicture ?? patient?.photo}
            onOpenPhoto={handleOpenPhoto}
            t={t}
          />
        </div>
      </div>
      {telemedicineStatus !== 'invalid' && (
        <div className="chat__input">
          <div className="container">
            <ChatInputGroup isError={errors.length} className="chat__input-group">
              <div className="chat__input-actions">
                {!isUploadButtonHidden && (<>
                  {isAvailable([FEATURE_HIGO]) && hasExamDevice ? (
                    <div className="chat__input-upload">
                      <UploadPopover>
                        {isPatient && (
                          <UploadPopover.List icon={FileText} text="Exam Report" onClick={onClickExamReport} />
                        )}
                        <UploadPopover.List icon={Paperclip} text="Upload Files" onClick={onClickUploadAttachment} />
                      </UploadPopover>
                    </div>
                  ) : (
                    <div className="chat__input-attachment">
                      <ImageUpload
                        filesContent={filesContent}
                        loading={loading}
                        errors={errors}
                        openFileSelector={onClickUploadAttachment}
                        t={t}
                      />
                    </div>
                  )}
                </>)
                }

                <div className="chat__input-box-wrapper">
                  {(plainFiles || []).map((file, index) => (
                    <div className="chat-attachment" key={index}>
                      <div className="chat-attachment__preview">
                        <div className="chat-attachment__preview-info">
                          <div className="thumbnail thumbnail--square">
                            <Png
                              name={file.type === 'application/pdf' ? 'ic-file' : filesContent[0].content}
                              className="thumbnail__image"
                              alt="uploaded"
                              external={file.type !== 'application/pdf'}
                            />
                          </div>
                          <p className="text-gray text-truncate">{file.name}</p>
                        </div>
                        <button className="button chat-attachment__preview-clear" onClick={clear}>
                          <Png name="ic-close" className="img-responsive" alt="close" width="10px" />
                        </button>
                      </div>
                    </div>
                  ))}
                  {filesContent.length === 0 && (
                    <textarea
                      autoComplete="off"
                      rows="1"
                      value={message}
                      onChange={handleChangeMessage}
                      onKeyDown={onKeyDownMessage}
                      onKeyUp={onKeyUpMessage}
                      id="chatInput"
                      type="text"
                      maxLength={MAX_LENGTH_CHAT}
                      className="chat__input-box"
                      placeholder={t('Type a message')}
                    />
                  )}
                </div>
                <button className="chat__input-send chat__input-send--active" onClick={handleSubmitMessage}>
                  <Png name="ic-send-2" className="img-responsive" alt="send message" width="13px" />
                </button>
              </div>
            </ChatInputGroup>
            <ErrorMessage>
              {errors?.[0]?.fileSizeToolarge &&
                t('File size should be less than or equal to {{maxFileSize}}MB.', {
                  maxFileSize: runtimeConfig?.REACT_APP_OKA_PUBLIC_CHAT_MAX_FILE_SIZE_UPLOAD ?? 2.1
                })}
            </ErrorMessage>
            <ErrorMessage>
              {errors?.[0]?.fileTypeInvalid && t('Only these file types are accepted: jpeg, jpg, png, pdf')}
            </ErrorMessage>
          </div>
        </div>
      )}

      <ModalPhoto photo={photo} show={isPhotoModalOpen} closeModal={handleCloseModal} />
    </>
  );
};

export default ChatView;
