import PropTypes from 'prop-types';
import moment from 'moment';
import { X, Info } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import Button from 'okadoc-component-ui/lib/Button';
import Checkbox from 'components/atoms/checkbox';
import useAction from '../actions';
import {
  ExamMobileScreenGlobalStyle,
  FullscreenModalContainer,
  FullscreenModal,
  FullscreenModalHeader,
  FullscreenModalClose,
  FullscreenModalHeaderGroup,
  FullscreenModalHeaderGroupItem
} from './styles';
import { AlertMessage } from '../desktop/styles';
import ExamReport from '../exam-report';
import { STEP_UPLOAD_EXAM, HEADER_TITLE } from '../constant';

const ExamMobileScreen = ({ t, open, showExamModal }) => {
  const {
    selectedExamReport,
    isAgree,
    privacyUrl,
    termsUrl,
    onClickAgree,
    onHide,
    onSubmit,
    step,
    handleNextStep,
    facilityName,
    currentSharedDocument
  } = useAction({
    t,
    open,
    showExamModal
  });

  if (!open) return null;

  return (
    <>
      <ExamMobileScreenGlobalStyle />
      <FullscreenModalContainer>
        <FullscreenModal>
          <FullscreenModalHeader>
            <FullscreenModalHeaderGroup>
              <FullscreenModalHeaderGroupItem>
                <FullscreenModalClose onClick={onHide}>
                  <X size={20} />
                </FullscreenModalClose>
              </FullscreenModalHeaderGroupItem>
              <p className="text-center flex-grow-1">{t(HEADER_TITLE[step])}</p>
              <FullscreenModalHeaderGroupItem></FullscreenModalHeaderGroupItem>
            </FullscreenModalHeaderGroup>
          </FullscreenModalHeader>
          {step === STEP_UPLOAD_EXAM.EXAM_REPORT_LIST && (
            <div className="p-20" style={{ overflowY: 'scroll' }}>
              <ExamReport onClick={handleNextStep} />
            </div>
          )}
          {step === STEP_UPLOAD_EXAM.CONFIRMATION && (
            <>
              <div className="d-flex flex-column flex-grow-1 p-20">
                <div className="d-flex flex-column">
                  <p className="text-bold">{selectedExamReport?.document_title}</p>
                  <p className="mt-10 text-gray">
                    <span>{t('Added by {{exam}}', { exam: selectedExamReport?.created_by })}</span>
                    <span className="mx-5">-</span>
                    <span>{moment(selectedExamReport?.last_updated_time).format('MMM DD, YYYY')}</span>
                  </p>
                </div>
                <div className="d-flex flex-column mt-20">
                  <AlertMessage>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 mr-10">
                        <Info color={Colors.primary.NavyBlue} size={24} weight="fill" />
                      </div>
                      <p>
                        {t(
                          'When you share a document with {{facilityName}}, this will be made available on their internal systems.',
                          { facilityName }
                        )}
                      </p>
                    </div>
                  </AlertMessage>
                  <div className="d-flex flex-grow-1 mt-20">
                    <Checkbox type="checkbox" id="chkTermsAgreement">
                      <Checkbox.Input
                        type="checkbox"
                        checked={isAgree}
                        onChange={onClickAgree}
                        onClick={e => e.stopPropagation()}
                      />
                      <Checkbox.Label onClick={e => e.stopPropagation()}>
                        {t('I have read and agree to the')}{' '}
                        <a
                          id="exam-terms-link"
                          class="exam-link"
                          href={termsUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Terms of Use')}
                        </a>{' '}
                        {t('and')}{' '}
                        <a
                          id="exam-privacy-link"
                          class="exam-link"
                          href={privacyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Privacy Policy')}
                        </a>
                      </Checkbox.Label>
                    </Checkbox>
                  </div>
                </div>
              </div>
              <div className="p-20">
                <Button size="small" onClick={onSubmit} disabled={!isAgree || currentSharedDocument} block>
                  {currentSharedDocument ? t('Loading') : t('Share')}
                </Button>
              </div>
            </>
          )}
        </FullscreenModal>
      </FullscreenModalContainer>
    </>
  );
};

ExamMobileScreen.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showExamModal: PropTypes.func.isRequired
};

export default ExamMobileScreen;
