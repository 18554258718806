import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ExamDesktopScreen from './desktop';
import ExamMobileScreen from './mobile';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';

const ExamModal = ({ open = false, exam = {}, showExamModal = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const isMobile = mobileBreakPoint();

  if (isMobile)
    return <ExamMobileScreen t={t} open={open} exam={exam} showExamModal={showExamModal} onSubmit={onSubmit} />;
  return <ExamDesktopScreen t={t} open={open} exam={exam} showExamModal={showExamModal} onSubmit={onSubmit} />;
};

ExamModal.propTypes = {
  open: PropTypes.bool,
  exam: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  showExamModal: PropTypes.func.isRequired
};

export default ExamModal;
