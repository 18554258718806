import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const AlertContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const AlertNotification = styled.div`
  @keyframes slideDown {
    from {
      margin-top: -20px;
    }
    to {
      margin-top: 0px;
    }
  }

  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  background-color: ${Colors.text.WhiteText};
  padding: 15px 10px;
  border-bottom: 1px solid ${Colors.grayscale.LineGreyLight};
  animation-name: slideDown;
  animation-duration: 0.3s;
  transition: 0.3s all ease;
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationImage = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationMessage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0px 10px;

  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

export const NotificationButton = styled.div`
  display: flex;
  align-items: center;
`;
