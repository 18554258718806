import { useEffect, useState } from 'react';
import { useGlobalConfigStore } from 'zustandStore';
import DocumentAPI from 'libs/api/document';
import useChat from 'libs/hooks/useChat';

const useActions = ({ onClick = () => {} }) => {
  const [examReportList, setExamReportList] = useState([]);
  const [loading, setLoading] = useState(true);
  const setSelectedExamReport = useGlobalConfigStore(state => state.setSelectedExamReport);
  const isExamFromAlert = useGlobalConfigStore(state => state.isExamFromAlert);
  const {
    value: {
      appointment: { appointmentNumber },
      patient: { patient_relative_id: patientRelativeId }
    }
  } = useChat();

  useEffect(() => {
    if (!isExamFromAlert) {
      getExamReportList();
    }
  }, [isExamFromAlert]);

  const getExamReportList = async () => {
    setLoading(true);
    const params = {
      fg_remote_exam: 1,
      appointment_number: appointmentNumber,
      patient_relative_id: patientRelativeId,
      tag_id: 11,
    };

    // eslint-disable-next-line new-cap
    const [, response] = await DocumentAPI.GetPatientDocumentsTelemedicine({ params });

    if (response) {
      setExamReportList(response?.data?.data);
      setLoading(false);
    }
  };

  const onClickExam = data => {
    setSelectedExamReport(data);
    onClick();
  };

  return { examReportList, loading, onClickExam };
};

export default useActions;
