import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';
import Skeleton from 'react-loading-skeleton';

export const ModalBody = styled.div`
  margin: 16px;
`;

export const Title = styled.h3`
  color: ${Colors.text.RhinoGrey};
  margin-bottom: 8px;
`;

export const SkeletonWrapper = styled.div`
  margin-top: 16px;
`;

export const SkeletonLoading = styled(Skeleton)`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex !important;
`;
