import styled, { createGlobalStyle } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const ExamDesktopScreenGlobalStyle = createGlobalStyle`
  .bootstrap-modal {
    &.exam-modal {
      &.modal {
        .modal-body {
          padding: 20px;
          font-size: 16px;
        }

        .modal-footer {
          padding: 15px 20px;

          > * {
            margin: 0px;
          }
        }

        @media (min-width: 576px) {
          .modal-dialog {
            max-width: 468px !important;
          }
        }
      }

      .modal-content {
        border: none;
      }
    }

  }

  a.exam-link {
    color: ${Colors.primary.NavyBlue};
    text-decoration: none;
  }
`;

export const HeaderTitle = styled.h4`
  font-size: 18px;
  font-weight: 700;
`;

export const AlertMessage = styled.div`
  display: block;
  background-color: ${Colors.extended.SoftBlue};
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
`;
