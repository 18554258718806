import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalConfigStore } from 'zustandStore';
import AppointmentAPI from 'libs/api/appointment';
import useChat from 'libs/hooks/useChat';
import useToast from 'libs/hooks/useToast';
import { EVENT_TYPE_IFRAME } from 'libs/constant';

const useActions = ({ file, openModal }) => {
  const [url, setUrl] = useState('');
  const iframeExamReportRef = useRef(null);

  const { t } = useTranslation();
  const { show } = useToast();
  const {
    value: { appointment }
  } = useChat();

  const appToken = useGlobalConfigStore(state => state.appToken);
  const dataExamReport = { ...file, url };

  useEffect(() => {
    if (openModal) {
      const fetchChatHistoryById = async () => {
        setUrl('');
        // eslint-disable-next-line new-cap
        const [, response] = await AppointmentAPI.GetChatHistory({
          appointmentNumber: appointment?.appointmentNumber,
          chatId: file.messageId
        });

        setUrl(response?.data?.data?.[0]?.text);
      };

      fetchChatHistoryById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, file.messageId, appointment?.appointmentNumber]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceived);
    return () => window.removeEventListener('message', onMessageReceived);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMessageReceived = useCallback(e => {
    let message = {};

    // * make sure only got the message listener from the application that we defined
    // * not from native
    if (typeof e?.data === 'string') {
      try {
        message = JSON.parse(e.data);
      } catch (_error) {
        show({
          type: 'danger',
          message: t('Something went wrong, please try again later')
        });
      }

      if (message?.type === EVENT_TYPE_IFRAME.DOCUMENT_READY) {
        const messageToken = JSON.stringify({
          type: EVENT_TYPE_IFRAME.AUTH,
          token: appToken
        });

        iframeExamReportRef?.current?.contentWindow?.postMessage(messageToken, '*');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    t,
    iframeExamReportRef,
    iframeExamReportUrl: url,
    dataExamReport
  };
};

export default useActions;
