import React from 'react';
import PropTypes from 'prop-types';
import { CardContainer, CardBody, CardRow, CardCol, IconSection, TruncateText, Middot, DocumentTitle } from './styles';
import { Image, FilePdf, File, FileAudio } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Colors from 'okadoc-component-ui/lib/Colors';

const ExamList = ({ data, onClick }) => {
  const { t } = useTranslation();
  const documentTitle = data?.document_title || t('Untitled Document');
  const createdBy = data?.created_by;
  const lastUpdateTime = data?.last_updated_time;

  const myStr = createdBy;
  const firstName = myStr.split(' ')[0];

  const Icon = () => {
    const fileName = data?.files[0]?.file_name;
    const lastDotIndex = fileName?.lastIndexOf('.');
    const examType = fileName?.slice(lastDotIndex + 1).toLowerCase();

    switch (examType) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <Image size={32} weight="light" color={Colors.primary.NavyBlue} />;
      case 'pdf':
        return <FilePdf size={32} weight="light" color={Colors.primary.NavyBlue} />;
      case 'mp4':
      case 'mp3':
      case 'wav':
        return <FileAudio size={32} weight="light" color={Colors.primary.NavyBlue} />;
      default:
        return <File size={32} weight="light" color={Colors.primary.NavyBlue} />;
    }
  };

  return (
    <CardContainer>
      <CardBody onClick={onClick}>
        <CardRow>
          <CardCol>
            <IconSection>
              <Icon />
            </IconSection>

            <TruncateText maxWidth={220}>
              <DocumentTitle>{documentTitle}</DocumentTitle>
              <p>
                {t('by')} {firstName}
                <Middot>&bull;</Middot>
                {moment(lastUpdateTime).format('ll')}
              </p>
            </TruncateText>
          </CardCol>
        </CardRow>
      </CardBody>
    </CardContainer>
  );
};

ExamList.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
};

export default ExamList;
