import styled, { createGlobalStyle } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const DesktopExamViewerGlobalStyle = createGlobalStyle`
  .bootstrap-modal.exam-report-modal {
    .modal-dialog {
      width: 80%;
      max-width: none !important;
    }
    .modal-content {
      min-height: 545px;
    }

    @media (min-width: 576px) {
      .modal-dialog-centered {
        min-height: calc(100% - -10rem) !important;
        max-width: 468px !important;
      }
    }
  }
`;

export const ExamHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ExamTitle = styled.div`
  color: ${Colors.text.RhinoGrey};
  flex: 2;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ExamBody = styled.div``;
