import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Colors from "okadoc-component-ui/lib/Colors";

export const CheckboxComponent = styled(Form.Check)`
  align-items: ${(props) => (props.centered ? "center" : "flex-start")};
  display: flex;

  &.form-check {
    padding-left: 0;
    margin-bottom: 0;
    min-height: auto;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 0;
    box-shadow: none;
    filter: none;
    border: solid 1px ${Colors.grayscale.MindfulGrey};
    flex-shrink: 0;

    &:checked {
      background-color: ${Colors.primary.NavyBlue};
      border-color: ${Colors.primary.NavyBlue};
    }

    &.form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 11l3 3l8-8'/%3e%3c/svg%3e");
    }
  }

  label {
    padding-left: 0.5rem;
    line-height: 1.25rem;
  }
`;
