import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalConfigStore } from 'zustandStore';
import { getQueryParams } from 'libs/url';
import DocumentAPI from 'libs/api/document';
import useToast from 'libs/hooks/useToast';
import useChat from 'libs/hooks/useChat';
import { STEP_UPLOAD_EXAM } from './constant';

const useAction = ({ t, open, showExamModal }) => {
  const {
    value: { appointment, doctor }
  } = useChat();
  const [currentSharedDocument, setCurrentSharedDocument] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [step, setStep] = useState(1);
  const { show } = useToast();
  const location = useLocation();
  const isExamFromAlert = useGlobalConfigStore(state => state.isExamFromAlert);
  const setIsExamFromAlert = useGlobalConfigStore(state => state.setIsExamFromAlert);
  const selectedExamReport = useGlobalConfigStore(state => state.selectedExamReport);
  const setSelectedExamReport = useGlobalConfigStore(state => state.setSelectedExamReport);
  const patientUrl = process.env.REACT_APP_URL_CF_OKADOC;
  const queryParams = getQueryParams(location);
  const country = queryParams?.country;
  const lang = queryParams?.lang;
  const documentId = isExamFromAlert ? selectedExamReport?.document_id : selectedExamReport?.id;

  useEffect(() => {
    if (open) {
      setIsAgree(false);
    }
  }, [open]);

  useEffect(() => {
    if (isExamFromAlert) {
      setStep(STEP_UPLOAD_EXAM.CONFIRMATION);
    }
  }, [isExamFromAlert]);

  const onClickAgree = e => {
    const { checked } = e.currentTarget;
    setIsAgree(checked);
  };

  const onHide = () => {
    showExamModal(false);
    setSelectedExamReport({});
    setIsExamFromAlert(false);
    setTimeout(() => {
      setStep(STEP_UPLOAD_EXAM.EXAM_REPORT_LIST);
    }, 300);
  };

  const onSubmit = async () => {
    if (!currentSharedDocument) {
      setCurrentSharedDocument(true);
      // eslint-disable-next-line new-cap
      const [err, response] = await DocumentAPI.PostPatientDocuments({
        documentId,
        params: {
          facility_id: +appointment?.facilityId,
          appointment_number: appointment?.appointmentNumber
        }
      });

      if (err || response) {
        setCurrentSharedDocument(false);
        onHide();
        if (err) {
          show({
            type: 'danger',
            message: t('Something went wrong, please try again later')
          });
        }
      }
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  return {
    selectedExamReport,
    isAgree,
    termsUrl: `${patientUrl}/${lang}-${country}/policy/terms`,
    privacyUrl: `${patientUrl}/policy/privacy`,
    onClickAgree,
    onHide,
    onSubmit,
    handleNextStep,
    step,
    facilityName: doctor?.location?.name,
    currentSharedDocument
  };
};

export default useAction;
