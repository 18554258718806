import to from '../../await-to';
import OkaAxios from '../../axios';

export default class DocumentAPI {
  static GetPatientDocumentsTelemedicine = async params => {
    const endpoint = `/patient/documents/telemedicine/gets?`;

    return to(OkaAxios.document.get(endpoint, params));
  };

  static PostPatientDocuments = async ({ documentId, params }) => {
    const endpoint = `/patient/documents/${documentId}/telemedicine/access`;

    return to(OkaAxios.document.post(endpoint, params));
  };
}
