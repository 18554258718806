import React from 'react';
import PropTypes from 'prop-types';
import ExamList from './exam-list';
import { Title, SkeletonLoading, SkeletonWrapper } from './styles';
import useActions from './actions';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash.isempty';

const ExamReport = ({ onClick }) => {
  const { examReportList, loading, onClickExam } = useActions({ onClick });
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('Select an exam report to share')}</Title>
      {loading && (
        <SkeletonWrapper>
          <SkeletonLoading count={2} />
        </SkeletonWrapper>
      )}
      {!loading &&
        !_isEmpty(examReportList) &&
        examReportList.map((data, index) => <ExamList key={index} data={data} onClick={() => onClickExam(data)} />)}
      {!loading && _isEmpty(examReportList) && <div>{t('No Exam Found')}</div>}
    </>
  );
};

ExamReport.propTypes = {
  onClick: PropTypes.func
};

export default ExamReport;
