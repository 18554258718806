/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import useChat from 'libs/hooks/useChat';
import isAvailable, { FEATURE_HIGO } from 'libs/feature-flags';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import LoadingModal from 'components/LoadingModal';
import UploadDocument from 'components/organisms/upload-document';
import ParticipantsView from '../partial/participant-view';
import ChatView from '../chat-view';
import InfoView from '../partial/info-view-event';
import { Container } from './styles';
import Alert from '../alert';
import ExamModal from 'components/organisms/exam-modal';
import useAction from './actions';

const VideoContainer = ({
  country,
  isPatient,
  lang,
  participantType,
  showChat,
  showFullChat,
  showInfo,
  showVideo,
  setShowFullChat,
  setShowVideo,
  setShowChat,
  setShowInfo,
  setIsUnread,
  wlVersion,
  isXToken
}) => {
  const { t } = useTranslation();
  const {
    value: { appointment, telemedicine }
  } = useChat();
  const uploadDocumentRef = useRef(null);
  const isMobile = mobileBreakPoint();

  const isOldWLVersion = +wlVersion === 1;
  const chatActive = !(isMobile && showInfo) && (showChat || showFullChat);
  const videoCallActive = isMobile ? showVideo && !showChat && !showInfo : showVideo;
  const hasExamDevice = telemedicine?.isExamDevice;

  const {
    sideBarRef,
    setSideBarRef,
    sideBarVideoRef,
    setSideBarVideoRef,
    isShowAppDrawerUpload,
    setIsShowAppDrawerUpload,
    isShowExamModal,
    setIsShowExamModal,
    showLoading
  } = useAction({ isPatient, videoCallActive, chatActive, hasExamDevice, appointment });

  return (
    <>
      <Container isOldWLVersion={isOldWLVersion}>
        {videoCallActive && (
          <div className={classnames('video-embed active video-joined')} id="okadocvideoconsultation">
            <ParticipantsView
              showVideo={showVideo}
              setShowInfo={setShowInfo}
              setShowChat={setShowChat}
              setShowFullChat={setShowFullChat}
              setShowVideo={setShowVideo}
              participantType={participantType}
              setIsUnread={setIsUnread}
            />
          </div>
        )}
        {chatActive && (
          <div ref={setSideBarVideoRef} className={`${showFullChat ? 'chat' : 'sidebar'} chat--sidebar`}>
            {isAvailable([FEATURE_HIGO]) && isPatient && hasExamDevice && (
              <Alert t={t} showExamModal={() => setIsShowExamModal(true)} />
            )}
            <ChatView
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              showChat={showChat}
              setShowChat={setShowChat}
              wlVersion={wlVersion}
              country={country}
              lang={lang}
              isPatient={isPatient}
              showFullChat={showFullChat}
              setShowFullChat={setShowFullChat}
              saveFacilityDocument={uploadDocumentRef.current?.saveFacilityDocument}
            />
          </div>
        )}
        <div ref={setSideBarRef} className={classnames('sidebar', 'chat--sidebar', { hide: !showInfo })}>
          <InfoView showVideo={showVideo} isXToken={isXToken} />
        </div>
        {/** Upload Document Screen For Doctor Web Only */}
        {!isPatient && (
          <UploadDocument
            ref={uploadDocumentRef}
            t={t}
            country={country}
            lang={lang}
            sideBarRef={videoCallActive && chatActive ? sideBarVideoRef : sideBarRef}
            isOpen={isShowAppDrawerUpload}
            isShowVideoScreen={videoCallActive && chatActive}
            wlVersion={wlVersion}
            onHide={() => setIsShowAppDrawerUpload(false)}
          />
        )}
        {isAvailable([FEATURE_HIGO]) && isPatient && hasExamDevice && (
          <ExamModal open={isShowExamModal} showExamModal={setIsShowExamModal} />
        )}
      </Container>
      <LoadingModal active={showLoading()} />
    </>
  );
};

export default VideoContainer;
