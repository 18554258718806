import { useGlobalConfigStore } from 'zustandStore';

const useAction = ({ showExamModal }) => {
  const newExamReportAlert = useGlobalConfigStore(state => state.newExamReportAlert);
  const setNewExamReportAlert = useGlobalConfigStore(state => state.setNewExamReportAlert);
  const setIsExamFromAlert = useGlobalConfigStore(state => state.setIsExamFromAlert);
  const setSelectedExamReport = useGlobalConfigStore(state => state.setSelectedExamReport);

  const onShareExamReport = () => {
    setIsExamFromAlert(true);
    setSelectedExamReport(newExamReportAlert);
    showExamModal();
    setNewExamReportAlert({});
  };

  return {
    newExamReport: newExamReportAlert,
    onShareExamReport
  };
};

export default useAction;
