import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { CheckboxComponent } from "./styles";

const Checkbox = ({ children = null, centered = false, ...rest }) => (
  <CheckboxComponent centered={centered} {...rest}>
    {children}
  </CheckboxComponent>
);

Checkbox.Label = Form.Check.Label;
Checkbox.Input = Form.Check.Input;

Checkbox.propTypes = {
  children: PropTypes.node,
  centered: PropTypes.bool,
};

export default Checkbox;
