import PropTypes from 'prop-types';
import AppDrawer from 'components/molecules/app-drawer';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import DesktopExamViewer from './desktop-exam-viewer';
import IframeExamViewer from './iframe-exam-viewer';
import useActions from './actions';

const ExamReportViewer = ({ file = { fileName: '', messageId: '' }, show = false, onHide = () => {} }) => {
  const { t, dataExamReport, iframeExamReportRef, iframeExamReportUrl } = useActions({ file, openModal: show });

  const isMobile = mobileBreakPoint();

  if (isMobile)
    return (
      <AppDrawer
        show={show}
        title={t('Examination Preview')}
        content={<IframeExamViewer src={iframeExamReportUrl} ref={iframeExamReportRef} title="exam-report-mobile" />}
        onHide={onHide}
      />
    );
  if (!isMobile)
    return <DesktopExamViewer file={dataExamReport} ref={iframeExamReportRef} show={show} onHide={onHide} />;
};

ExamReportViewer.propTypes = {
  file: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default ExamReportViewer;
