import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e8e9ea;
  margin: 16px 0px;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 24px;
  cursor: pointer;
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardCol = styled.div`
  display: flex;
  align-items: center;
`;

export const IconSection = styled.div`
  margin-top: 5px;
  align-items: center;
`;

export const TruncateText = styled.div`
  margin-left: 16px;
  p {
    max-width: ${props => (props.maxWidth ? props.maxWidth : 100)}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }

  p.title {
    line-height: 1.572;
  }

  p.author {
    line-height: 2;
  }
`;

export const Middot = styled.span`
  font-size: 10px;
  margin-left: 8px;
  margin-right: 8px;
  color: #b3b6ba;
  vertical-align: middle;
`;

export const DocumentTitle = styled.h4`
  color: ${Colors.text.RhinoGrey};
  margin-bottom: 8px;
  font-size: 14px;
`;
