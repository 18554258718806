import { useGlobalConfigStore, useServerEventsStore } from 'zustandStore';
import { CONSULTATION_STATUS, EVENTS_TYPE_SSE } from 'libs/constant';
import useServerSideEvent from 'libs/hooks/useServerSideEvent';
import { sendMessage } from 'libs/message';
import { getQueryParams } from 'libs/url';
import useChat from 'libs/hooks/useChat';

export const useServerEventTelemedicine = () => {
  const params = getQueryParams(window.location);
  const domain = params?.domain;

  const { value } = useChat();
  const isDoctorView = value?.isDoctor;
  const setIsEndSession = useGlobalConfigStore(state => state.setIsEndSession);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const onUpdateTelemedicineStartTime = useServerEventsStore(state => state.updateTelemedicineStartTime);

  const onMessage = (event, message) => {
    const startTime = message?.start_time;
    const updateStatus = {
      participantStatus: {
        practitioner: message?.practitioner_status,
        patient: message?.patient_status
      }
    };

    toggleState(updateStatus);

    if (startTime) onUpdateTelemedicineStartTime(startTime);

    const msg =
      event === EVENTS_TYPE_SSE.SESSION_END
        ? {
            ...message,
            practitioner_status: CONSULTATION_STATUS.COMPLETED
          }
        : message;

    if (
      msg?.practitioner_status === CONSULTATION_STATUS.COMPLETED ||
      msg?.patient_status === CONSULTATION_STATUS.COMPLETED
    ) {
      setIsEndSession(true);
    }

    sendMessage({
      message: {
        type: 'telemedicine_stream_status',
        streamData: msg
      },
      domain
    });
  };

  const onError = error => {
    console.error(error);
  };

  useServerSideEvent({
    onMessage,
    onError,
    deps: [domain, isDoctorView]
  });
};
