import PropTypes from 'prop-types';
import { FileArrowUp } from 'phosphor-react';
import Button from 'okadoc-component-ui/lib/Button';
import Colors from 'okadoc-component-ui/lib/Colors';
import {
  AlertContainer,
  AlertNotification,
  Notification,
  NotificationImage,
  NotificationMessage,
  NotificationButton
} from './styles';
import useAction from './actions';

const Alert = ({ t, showExamModal }) => {
  const { newExamReport, onShareExamReport } = useAction({ showExamModal });
  if (!newExamReport?.document_id) return null;
  return (
    <AlertContainer>
      <AlertNotification>
        <Notification>
          <NotificationImage>
            <FileArrowUp size={32} weight="fill" color={Colors.primary.NavyBlue} />
          </NotificationImage>
          <NotificationMessage>
            <div>{t('New Exam Report:')}</div>
            <div>{newExamReport?.document_title}</div>
          </NotificationMessage>
          <NotificationButton>
            <Button size="small" onClick={onShareExamReport} block>
              {t('Share')}
            </Button>
          </NotificationButton>
        </Notification>
      </AlertNotification>
    </AlertContainer>
  );
};

Alert.propTypes = {
  t: PropTypes.func.isRequired,
  showExamModal: PropTypes.func.isRequired
};

export default Alert;
