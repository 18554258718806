import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { PlusCircle } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import PropTypes from 'prop-types';
import { MenuUl, Popover, MenuList, MenuItem, MenuItemIcon, MenuItemText, IconWrapper } from './styles';

const UploadPopover = ({ children }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [popperVisible, setPopperVisible] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'eventListeners',
        options: {
          enabled: popperVisible
        }
      }
    ]
  });

  const onClickPopOver = () => setPopperVisible(prevState => !prevState);

  return (
    <>
      <IconWrapper>
        <PlusCircle
          onClick={onClickPopOver}
          ref={setReferenceElement}
          size={24}
          weight={popperVisible ? 'fill' : 'regular'}
          color={Colors.primary.NavyBlue}
        />
      </IconWrapper>
      {popperVisible && (
        <Popover
          ref={setPopperElement}
          style={{
            ...styles.popper,
            transform: 'translate3d(20px, -73px, 0px)'
          }}
          {...attributes.popper}
        >
          <MenuUl onClick={onClickPopOver}>{children}</MenuUl>
        </Popover>
      )}
    </>
  );
};

const UploadList = ({
  icon = null,
  text = 'text',
  onClick = () => {
    // enter code here
  },
  disabled = false
}) => {
  const uploadIcon = Icon => <Icon color={Colors.primary.NavyBlue} size={24} />;

  return (
    <MenuList onClick={onClick} disabled={disabled}>
      <MenuItem>
        {icon && <MenuItemIcon>{uploadIcon(icon)}</MenuItemIcon>}
        <MenuItemText>{text}</MenuItemText>
      </MenuItem>
    </MenuList>
  );
};

UploadPopover.Item = MenuItem;
UploadPopover.ItemIcon = MenuItemIcon;
UploadPopover.ItemText = MenuItemText;
UploadPopover.List = UploadList;

UploadPopover.propTypes = {
  children: PropTypes.node
};

UploadList.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default UploadPopover;
