import styled, { createGlobalStyle } from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const ExamMobileScreenGlobalStyle = createGlobalStyle`
  a.exam-link {
    color: ${Colors.primary.NavyBlue};
    text-decoration: none;
  }
`;

export const FullscreenModalContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(0 0 0 / 25%);
`;

export const FullscreenModal = styled.div`
  @keyframes slideUp {
    from {
      margin-top: 20px;
    }
    to {
      margin-top: 0px;
    }
  }

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: ${Colors.text.WhiteText};
  animation-name: slideUp;
  animation-duration: 0.3s;
  transition: 0.3s all ease;
`;

export const FullscreenModalHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid ${Colors.grayscale.LineGreyLight};
`;

export const FullscreenModalHeaderGroup = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
`;

export const FullscreenModalHeaderGroupItem = styled.div`
  display: flex;
  flex: 0 1 auto;
  width: 20%;
`;

export const FullscreenModalClose = styled.button`
  padding: 15px;
  display: flex;
`;
