import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import { Iframe } from './styles';

const IframeExamViewer = forwardRef(({ src, title }, ref) => {
  const isMobile = mobileBreakPoint();

  return <Iframe src={src} title={title} ref={ref} $isMobile={isMobile} />;
});

IframeExamViewer.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default IframeExamViewer;
