import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const Popover = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(47, 48, 50, 0.12);
  height: auto;
  padding: 8px 0;
  min-width: 154px;
  z-index: 1300;
`;

export const MenuUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const MenuList = styled.li`
  color: ${Colors.text.RhinoGrey};
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;

  &:hover {
    background: ${Colors.extended.WashedBlue};
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const MenuItem = styled.div`
  align-items: center;
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
`;

export const MenuItemIcon = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 2px;
`;

export const MenuItemText = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;
