import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { X } from 'phosphor-react';
import BootstrapModal from 'components/atoms/bootstrap-modal';
import { DesktopExamViewerGlobalStyle, ExamHeader, ExamTitle, ExamBody } from './styles';
import IframeExamViewer from '../iframe-exam-viewer';

const DesktopExamViewer = forwardRef(({ file = { url: '', fileName: '' }, show = false, onHide = () => {} }, ref) => {
  return (
    <>
      <DesktopExamViewerGlobalStyle />
      <BootstrapModal className="exam-report-modal" show={show} centered onHide={onHide}>
        <BootstrapModal.Body>
          <ExamHeader>
            <ExamTitle>{file.fileName}</ExamTitle>
            <div className="flex-shrink-0 pl-5">
              <X size={24} weight="light" onClick={onHide} style={{ cursor: 'pointer' }} />
            </div>
          </ExamHeader>
          <ExamBody>
            <IframeExamViewer src={file.url} ref={ref} title="exam-report-desktop" />
          </ExamBody>
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  );
});

DesktopExamViewer.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired
  }),
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default DesktopExamViewer;
